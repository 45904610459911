import "./_Sale.scss";
import Form from "./../form/Form";

const Sale = () => {
  return (
    <div className="container-fluid pt-5 sale-bg">
      <div className="container">
        <h1 className="gmp text-center">
          Consultanos por el servicios que mejor se adapte a tus necesidades.
        </h1>

        <Form />
      </div>
    </div>
  );
};

export default Sale;
