import "./_Container.scss";

import Header from "../shared/header/Header";
import AppRouter from "../../routers/AppRouter";

import Footer from "../shared/footer/Footer";

const Container = () => {
  return (
    <div className="main-container">
      <Header />
      <AppRouter />
      <Footer />
    </div>
  );
};

export default Container;
