import "./_Clients.scss";
import ClientCards from "./../clientsCards/ClientCards";

const Clients = () => {
  return (
    <div className="container-fluid pt-5 clients-bg">
      <ClientCards />
    </div>
  );
};

export default Clients;
