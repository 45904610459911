import "./_NormasList.scss";

const NormasList = () => {
  return (
    <div className="container gmp">
      <h1 className="mb-4 list-text text-center">
        NA & Asociados puede asesorarlo de la mejor manera con respecto a una
        gran variedad de Normas
      </h1>
      <div className="row">
        <div className="col">
          <ul>
            <li className="list-text">· Calidad (ISO 9001)</li>
            <li className="list-text">· Ambiental (ISO 14001) </li>
            <li className="list-text">
              · Seguridad y Salud en el Trabajo (ISO 45001){" "}
            </li>
            <li className="list-text">· Eficiencia Energética (ISO 50001)</li>
            <li className="list-text">· Gestión de Activos (ISO 55001) </li>
          </ul>
        </div>
        <div className="col">
          <ul>
            <li className="list-text">
              · Sistemas Integrados (ISO 9001 + ISO 14001 + ISO 45001 + ... ){" "}
            </li>
            <li className="list-text">
              · Gestión de inmuebles y servicios de soporte – Facility
              Management (ISO 41001)
            </li>
            <li className="list-text">
              · Gestión de Seguridad Vial (ISO 39001)
            </li>
            <li className="list-text">· Gestión de Reclamos (IRAM 90600)</li>
            <li className="list-text">
              · Continuidad del Negocio (ISO 19600){" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NormasList;
