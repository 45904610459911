import "./_Home.scss";
import Carousel from "./../carousel/Carousel";
import AboutUs from "./../aboutus/AboutUs";

const Home = () => {
  return (
    <div>
      <Carousel />
      <div className="companyName-container">
        <h1 id="CompanyName"> NA & Asociados</h1>
      </div>
      <div className="container-fluid home-bg">
        <AboutUs />
      </div>
    </div>
  );
};

export default Home;
