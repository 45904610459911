import "./_AboutUs.scss";
import Banner from "./../banner/Banner";
import AboutUsImg1 from "./../../assets/img/aboutus.jpg";

const AboutUs = () => {
  return (
    <div>
      <div className="container pt-5">
        <div className="blank-container">
          <h2>
            <u>PROFESIONALISMO, EFECTIVIDAD Y COMPROMISO</u>
          </h2>
          <p>
            Somos un equipo de profesionales altamente calificados, con
            importante trayectoria en el mercado, y dedicados a asistir a las
            organizaciones en la búsqueda de la excelencia.
          </p>
          <p>
            Con más de 15 años en el mercado, nuestro objetivo es brindar a las
            organizaciones, una amplia gama de servicios orientados al
            desarrollo y mejora de su gestión integral, en todas sus áreas
          </p>
          <p>
            Nuestros clientes valoran la proximidad y efectiva comunicación que
            mantenemos con ellos. Nuestro estilo de relacionamiento hace que nos
            consideren sus asesores de confianza.
          </p>
        </div>
        <div className="img-container">
          <img src={AboutUsImg1} className="d-block w-100 img" alt="..." />
        </div>
      </div>
      <Banner />
      <div className="container mt-5">
        <div className="blank-container">
          <h2>
            <u>IMPLEMENTACIÓN DE SISTEMAS DE GESTIÓN</u>
          </h2>
        </div>
        <div className="container">
          <div className="row gx-5">
            <div className="col-sm-12 col-md-6 mb-3">
              <div className="text-center gmp">
                Los Sistemas de Gestión basados en procesos de negocios tienen
                como principales aspectos, la mejora continua y el cumplimiento
                de los requisitos de los diferentes grupos de interés.
              </div>
            </div>
            <div className="col-sm-12 col-md-6">
              <div className="text-center gmp">
                La Adopción y Certificación de un Sistema de Gestión (Calidad,
                Medio Ambiente, Seguridad y Salud Ocupacional, etc.) es una
                decisión estratégica para una Organización.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="blank-container">
          <h2 className="text-center">
            NA & Asociados lo asiste en el Desarrollo, Implementación,
            Actualización, Certificación y Mantenimiento de sus Sistemas de
            Gestión basados en normas nacionales e internacionales
          </h2>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
