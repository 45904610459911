import "./_ClientCards.scss";
// import Client1 from "./../../assets/img/clients/1.png";
// import Client2 from "./../../assets/img/clients/2.svg";
// import Client3 from "./../../assets/img/clients/3.svg";
// import Client4 from "./../../assets/img/clients/4.png";
// import Client5 from "./../../assets/img/clients/5.png";
// import Client6 from "./../../assets/img/clients/6.png";
// import Client7 from "./../../assets/img/clients/7.png";
// import Client8 from "./../../assets/img/clients/8.png";

import ClientsImg1 from "./../../assets/img/clients.jpg";
const ClientCards = () => {
  return (
    <div className="container">
      <div>
        <h1 className="gmp text-center">
          Las empresas que confiaron en nosotros.
        </h1>
        <img src={ClientsImg1} className="d-block w-100 img mb-5" alt="..." />
      </div>
      <div className="row py-3">
        <div className="col-sm-3 pb-3">
          <div className="card gmp">
            <div className="card-body d-flex justify-content-evenly">
              {/* <img src={Client1} class="card-img-top p-5" alt="Mantelectric" /> */}
              <h2 className="card-title">Mantelectric</h2>
              <p className="card-text">
                Implementación ISO 9001, ISO 14001, ISO 39001, ISO 45001, ISO
                50001, ISO 55001.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 pb-3">
          <div className="card gmp">
            <div className="card-body d-flex justify-content-evenly">
              {/* <img src={Client2} class="card-img-top p-5" alt="Pfisterer" /> */}
              <h2 className="card-title">Pfisterer</h2>
              <p className="card-text">
                Implementación ISO 9001, ISO 14001 e ISO 45001. Gestión de
                Negocios.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 pb-3">
          <div className="card gmp">
            <div className="card-body d-flex justify-content-evenly">
              {/* <img
                src={Client3}
                class="card-img-top p-5 w-50"
                alt="Estudiantes de La Plata"
              /> */}
              <h2 className="card-title">Club Estudiantes de La Plata</h2>
              <p className="card-text">
                Implementación y Certificación de Protocolo Covid-19 para Centro
                de Entrenamiento del Fútbol Profesional (EDP IRAM 3820).
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="card gmp">
            <div className="card-body d-flex justify-content-evenly">
              {/* <img
                src={Client4}
                class="card-img-top p-5"
                alt="Cinco de Septiembre"
              /> */}
              <h2 className="card-title">Cinco de Septiembre</h2>
              <p className="card-text">
                Implementación ISO 9001, ISO 14001 e ISO/IEC 90003.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-3 pb-3">
          <div className="card gmp">
            <div className="card-body d-flex justify-content-evenly">
              {/* <img
                src={Client5}
                class="card-img-top p-5"
                alt="Gral. Saneamientos"
              /> */}
              <h2 className="card-title">Gral. Saneamientos</h2>
              <p className="card-text">Implementación ISO 9001.</p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 pb-3">
          <div className="card gmp">
            <div className="card-body d-flex justify-content-evenly">
              {/* <img
                src={Client6}
                class="card-img-top p-5"
                alt="Patrones de Cabotaje"
              /> */}
              <h2 className="card-title">Patrones de Cabotaje</h2>
              <p className="card-text">Implementación ISO 9001.</p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 pb-3">
          <div className="card gmp">
            <div className="card-body d-flex justify-content-evenly">
              {/* <img src={Client7} class="card-img-top p-5" alt="ABSA" /> */}
              <h2 className="card-title">ABSA</h2>
              <p className="card-text">Gestión de Intereses.</p>
            </div>
          </div>
        </div>
        <div className="col-sm-3 pb-3">
          <div className="card gmp">
            <div className="card-body d-flex justify-content-evenly">
              {/* <img src={Client8} class="card-img-top p-5" alt="riva" /> */}
              <h2 className="card-title">RIVA</h2>
              <p className="card-text">
                Implementación ISO 9001, ISO 14001 e ISO 45001.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCards;
