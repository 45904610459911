import "./_Footer.scss";
import LogoVGL from "../../../assets/img/LogoVGL.png";

const Footer = () => {
  return (
    <div>
      <div className="content-wrap d-none"></div>
      <div
        id="footer"
        className="container-fluid d-flex justify-content-evenly align-items-center no-select p-3"
      >
        <p className="footer-text text-center">
          © 2022 · NA & Asociados · Todos los derechos reservados · C.A.B.A.,
          Argentina
        </p>
        <span className="d-flex flex-row justify-content-center align-items-center footer-text opacity-75">
          <p className="footer-text d-none d-sm-none d-md-block text-end">
            Diseñado y desarrollado por
          </p>
          <a
            target="_blank"
            href="https://www.vgl.com.ar/"
            rel="noreferrer noopener"
          >
            <img
              className="mb-2 ms-1 p-1"
              src={LogoVGL}
              alt=""
              width="30"
            ></img>
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
