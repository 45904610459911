import "./_Services.scss";
import Banner from "./../banner/Banner";
import NormasList from "./../normasList/NormasList";
import ServicesCards from "./../servicesCards/ServicesCards";

const Services = () => {
  return (
    <div className="container-fluid pt-5 services-bg">
      <NormasList />
      <Banner />
      <ServicesCards />
    </div>
  );
};

export default Services;
