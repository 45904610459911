import "./_Form.scss";
import CotizacionImg1 from "./../../assets/img/cotizacion.jpg";

const Form = () => {
  return (
    <div className="gmp container d-flex justify-content-center align-items-center flex-column p-5">
      <img src={CotizacionImg1} className="d-block w-100 img mb-5" alt="..." />
      <h4 className="text-center">
        Hacé clic en el siguiente botón para descargar el formulario de
        cotización.
      </h4>
      <a
        className="form-btn mt-2 mb-3 text-center"
        rel="noopener noreferrer"
        href="https://nayasociados.com.ar/SolicitudDeCotizacion-NAYA.pdf"
        target="_blank"
        download
      >
        Descargar formulario de cotización
      </a>
      <h4 className="text-center">
        Una vez completo, envialo a{" "}
        <a href="mailto:info@nayasociados.com">info@nayasociados.com</a>
      </h4>
    </div>
  );
};

export default Form;
