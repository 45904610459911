import { Routes, Route } from "react-router-dom";
import HomeScreen from "../views/HomeScreen";
import ServicesScreen from "../views/ServicesScreen";
import ClientsScreen from "../views/ClientsScreen";
import SaleScreen from "../views/SaleScreen";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/servicios" element={<ServicesScreen />} />
      <Route path="/nuestrosclientes" element={<ClientsScreen />} />
      <Route path="/cotizacion" element={<SaleScreen />} />
      <Route path="/" element={<HomeScreen />} />
      <Route path="*" element={<HomeScreen />} />
    </Routes>
  );
};

export default AppRouter;
