import "./_ServicesCards.scss";

const ServicesCards = () => {
  return (
    <div className="container mt-5">
      <div className="row mb-3">
        <div className="col-sm-6 mb-3">
          <div className="card gmp">
            <div className="card-body">
              <h5 className="card-title">
                ASESORAMIENTO LEGAL ASOCIADO A SISTEMAS DE GESTIÓN
              </h5>
              <p className="card-text">
                Nuestro equipo de expertos legales pueden asistirlo en la
                Identificación, Evaluación de Aplicabilidad y Verificación de
                Cumplimiento de los requisitos legales asociados a la
                implementación de sus Sistemas de Gestión Ambiental, de
                Seguridad y Salud en el Trabajo y de Gestión de la Energía,
                entre otros:
              </p>
              <ul>
                <li>
                  · Construcción y Actualización de Matrices Legales, según
                  alcance, temática y jurisdicción aplicable
                </li>
                <li>· Ejecución de Auditorías de Cumplimiento Legal</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card gmp">
            <div className="card-body">
              <h5 className="card-title">GESTIÓN DE INTERESES</h5>
              <p className="card-text">
                Nuestro objetivo es brindar servicios para canalizar datos y
                gestiones que optimicen la relación pública institucional de las
                Organizaciones y que faciliten el logro de negocios ante:
              </p>
              <ul>
                <li>· Agregadurías comerciales de embajadas</li>
                <li>· Cámaras de comercio e industria</li>
                <li>· Empresas, en especial las PyMEs</li>
                <li>· Gobierno nacional</li>
                <li>· Gobiernos provinciales</li>
                <li>
                  · Otros organismos oficiales e instituciones particulares
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-sm-6 mb-3">
          <div className="card gmp">
            <div className="card-body">
              <h5 className="card-title">
                CONTROL INTERNO, SISTEMAS DE LUCHA CONTRA LA CORRUPCIÓN
              </h5>
              <p className="card-text">
                El Control Interno y la Gestión de Integridad sirven como la
                primera línea de defensa en la protección de activos, ayudando a
                la Máxima Autoridad Institucional y al Equipo Directivo de una
                entidad a obtener los resultados deseados a través de la
                efectiva administración de los recursos públicos
              </p>
              <p className="card-text">
                Los sistemas de Lucha contra la Corrupción ayudan a las
                organizaciones a evitar o mitigar la costos, riesgos y daños de
                participación en actos de corrupción, y a promover la confianza
                en los negocios, mejorando su reputación
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card gmp">
            <div className="card-body">
              <h5 className="card-title">ASESORAMIENTO TÉCNICO TRIBUTARIO</h5>
              <p className="card-text">
                Los asistimos ante cualquier necesidad de soluciones contables o
                impositivas. Evaluamos la situación, diagnosticamos y proponemos
                las mejores soluciones de la mano de nuestra alianza estratégica
                con uno de los estudios jurídicos especializados, de alto nivel
                y reconocimiento.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCards;
