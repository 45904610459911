import "./_Navbar.scss";
import { Link } from "react-router-dom";
import "animate.css";

const Navbar = () => {
  return (
    <div className="navbar-nav animate__animated animate__fadeInDown">
      <Link className="nav-item me-5 mb-3 pt-3" to="/">
        Home
      </Link>
      <Link className="nav-item me-5 mb-3 pt-3" to="/servicios">
        ¿Qué hacemos?
      </Link>
      <Link className="nav-item me-5 mb-3 pt-3" to="/nuestrosclientes">
        Nuestros clientes
      </Link>
      <Link className="nav-item me-5 mb-3 pt-3" to="/cotizacion">
        Cotizacion
      </Link>
    </div>
  );
};

export default Navbar;
